import { IonContent } from '@ionic/react'

const RegistreContainer = () => {
    return (
        <IonContent>

        </IonContent>
    )
}

export default RegistreContainer
